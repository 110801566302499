* {
  box-sizing: border-box;
}

.ais-Hits-list {
  width: 100%;
  display: flex;
}

.ais-Hits-item {
  box-shadow: none;
  border: 0;
  width: 100%;
  display: flex;
}

.header {
  background: #252321;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  display: flex;
  position: fixed;
}

.header__container {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 50px;
  display: flex;
}

.header__logo-container {
  min-width: 150px;
}

.header__logo svg {
  fill: #fff;
}

.header__searchbox-container {
  flex-grow: 1;
  height: 32px;
  margin-left: 5%;
}

.header__searchbox input {
  background-color: #fff;
  border-radius: 2px;
  padding: 8px 26px;
}

.header__searchbox input:focus {
  border: 1px solid #252321;
  border-bottom: 0;
  border-radius: 2px 2px 0 0;
  outline: none;
}

.results {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.results__container {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.facet-panel {
  flex: 1;
}

.search-panel {
  flex-wrap: wrap;
  flex: 3;
  display: flex;
}

.search-panel__results li {
  justify-content: space-between;
  width: 255px;
  max-height: 430px;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

.ais-RefinementList-list {
  border-radius: 10px;
  padding: 1em;
  box-shadow: 0 4px 8px #0003;
}

.result-hit__image-container {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.result-hit__image {
  flex-shrink: 0;
  min-width: 25%;
  min-height: 25%;
}

.result-hit {
  text-align: center;
  border: 2px solid #252321;
  border-radius: 5px;
  flex-direction: column;
  justify-content: end;
  min-width: 250px;
  margin: 1em;
  font-family: arial;
  display: flex;
  box-shadow: 0 4px 8px #0003;
}

.result-hit:hover {
  box-shadow: 0 8px 16px #0003;
}

.result-hit__prices {
  justify-content: space-around;
  display: flex;
}

.result-hit__original {
  color: red;
  font-size: 22px;
  text-decoration: line-through;
}

.result-hit__price {
  color: gray;
  overflow-wrap: break-word;
  font-size: 15px;
}

.result-hit__controls {
  bottom: 0;
}

.result-hit__controls > .result-hit__cart, .add-to-cart {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #000;
  border: none;
  outline: 0;
  width: 100%;
  padding: 12px;
  font-size: 18px;
}

.result-hit__controls > .result-hit__view {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: gray;
  border: none;
  outline: 0;
  width: 100%;
  padding: 12px;
  font-size: 18px;
}

button {
  align-items: center;
  display: flex;
}

.result-hit__controls button:hover, .add-to-cart:hover {
  opacity: .7;
}

#searchbox {
  margin-bottom: 2rem;
}

#pagination {
  text-align: center;
  margin: 2rem auto;
}

.cart-container {
  background-color: #fff;
  border-radius: 5px;
  width: 450px;
  margin-top: 28em;
  margin-right: 13%;
  padding: 0 0 0 .5em;
  position: absolute;
  right: 0;
  box-shadow: 0 4px 8px #0003;
}

.cart-container > h4 {
  flex-flow: row;
  align-content: center;
  display: flex;
}

.hidden {
  visibility: hidden;
}

span.price {
  float: right;
  color: gray;
}

.checkout-options {
  flex-direction: column;
  display: flex;
}

.checkout-options > button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: gray;
  border: none;
  border-radius: 5px;
  outline: 0;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  font-size: 15px;
}

.checkout-options > button:hover {
  background-color: #000;
}
/*# sourceMappingURL=index.bf2c5c71.css.map */
